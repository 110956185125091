import React, { useState, useEffect } from "react"
import AgeGate from "../components/AgeGate"


export default function AgeGatepage({}) {

    useEffect(() => {

    }, [])

    return (
        <div>
          <AgeGate ruta={'roadmap'}/>
        </div>
    )
}